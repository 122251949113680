
import themeMixin from '~/mixins/theme'

export default {
  name: 'HeroCardWithDescriptionAndButton',
  mixins: [themeMixin],
  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  computed: {
    themeComputedClass() {
      return this.getThemeClass(this.slice.primary.theme || this.themeColor)
    },
    buttonLinkGroupData() {
      const data = Object.assign({}, this.slice.primary)
      data.theme = data.buttonTheme || undefined
      return data
    },
  },
}
