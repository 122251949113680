
import DefaultComponent from './default-slice'
import WithDescriptionAndButton from './HeroCardWithDescriptionAndButton'

export default {
  name: 'HeroCard',
  components: {
    DefaultComponent,
    WithDescriptionAndButton,
  },
  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  computed: {
    variation() {
      return this.slice.variation
    },
    component() {
      const mappings = {
        'default-slice': 'DefaultComponent',
        WithDescriptionAndButton: 'WithDescriptionAndButton',
      }

      return mappings[this.variation] || 'DefaultComponent'
    },
  },
}
